import React from 'react';

import type {Role} from '@constants/wow';

import type {Character} from '../../../models/character';

import type {InstanceFilterOption} from '../Loot';
import type {ISelectOption as IFilterOption} from '../../Utils/FilterSelect';
import type {ISelectOption} from '../../Utils/SelectInput';
import {HotkeyCombo, Hotkey, HotkeyComboJoiner, isMac} from '../../Utils/Hotkey';
import {FilterControls} from './FilterControls';
import {OwnCharacters} from './OwnCharacters';
import {FilterItems} from './FilterItems';
import {BumpButton} from './BumpButton';
import {RoleFilter} from './RoleFilter';

interface IProps {
	onLootOptionSelect(lootOptionId: LootOptionId): void;
	onRoleSelect(role: string, isSelected: boolean): void;
	onCharacterSelect(characterId: number | null): void;
	onDifficultySelect(difficulty: string): void;
	onInstanceSelect(wowInstanceId: InstanceFilterOption): void;
	onRosterSelect(rosterId: number): void;
	onBump(characterId: number): void;
	onToggleDroptimizerRelativeGain(value: boolean): void;
	onToggleLootShowConduits(value: boolean): void;
	onToggleLootGroupBySlot(value: boolean): void;
	onShowExportLootInfoModal(): void;

	isShowingRclcButton: boolean;
	isAllowedToEdit: boolean;
	isCharacterAdmin: boolean;
	isCharacterOwner: boolean;
	canUseTagging: boolean;
	canUseExportLootInfo: boolean;

	isDroptimizerRelativeGain: boolean;
	isGroupingBySlot: boolean;
	isShowingConduits: boolean;
	isBumping: boolean;

	characterSelectOptions: IFilterOption[];
	rosterSelectOptions: ISelectOption[];
	instanceSelectOptions: ISelectOption[];
	lootOptionSelectOptions: ISelectOption[];

	activeCharacter: Character | undefined;
	ownCharacters: Character[];

	activeRoles: Role[];
	selectedLootOptionIds: LootOptionId[];
	difficulty: string;
	wowInstanceId: InstanceFilterOption;
	characterId: CharacterId | undefined;
	rosterId: RosterId | undefined;
	rclcUrl: string;
}

export const TopSection: React.FC<IProps> = (props) => {
	const showEditReason =
		props.characterId &&
		props.isAllowedToEdit &&
		(props.isCharacterAdmin || props.isCharacterOwner);

	const showKeybindInfo = !props.characterId && props.isCharacterAdmin;

	return (
		<>
			<div className="view-controls">
				<FilterControls
					onLootOptionSelect={props.onLootOptionSelect}
					onCharacterSelect={props.onCharacterSelect}
					onDifficultySelect={props.onDifficultySelect}
					onInstanceSelect={props.onInstanceSelect}
					onRosterSelect={props.onRosterSelect}
					onShowExportLootInfoModal={props.onShowExportLootInfoModal}
					canUseExportLootInfo={props.canUseExportLootInfo}
					canChangeRoster={props.canUseTagging}
					isShowingRclcButton={props.isShowingRclcButton}
					characterSelectOptions={props.characterSelectOptions}
					rosterSelectOptions={props.rosterSelectOptions}
					instanceSelectOptions={props.instanceSelectOptions}
					lootOptionSelectOptions={props.lootOptionSelectOptions}
					selectedLootOptionIds={props.selectedLootOptionIds}
					difficulty={props.difficulty}
					wowInstanceId={props.wowInstanceId}
					characterId={props.characterId}
					rosterId={props.rosterId}
					rclcUrl={props.rclcUrl}
				/>

				<div className="secondary-controls">
					<OwnCharacters
						onCharacterSelect={props.onCharacterSelect}
						ownCharacters={props.ownCharacters}
					/>

					<FilterItems
						onToggleDroptimizerRelativeGain={props.onToggleDroptimizerRelativeGain}
						onToggleLootGroupBySlot={props.onToggleLootGroupBySlot}
						onToggleLootShowConduits={props.onToggleLootShowConduits}
						isShowingDroptimizerRelativeGain={props.isDroptimizerRelativeGain}
						isShowingGroupBySlot={props.isGroupingBySlot}
						isShowingConduits={props.isShowingConduits}
						wowInstanceId={props.wowInstanceId}
					/>
				</div>
			</div>

			{props.activeCharacter && (
				<div className="char-controls">
					<RoleFilter
						onClick={props.onRoleSelect}
						selectedRoles={props.activeRoles}
						charClass={props.activeCharacter.class}
					/>

					{props.isAllowedToEdit && props.characterId && (
						<BumpButton
							onClick={() => props.characterId && props.onBump(props.characterId)}
							isSubmitting={props.isBumping}
						/>
					)}
				</div>
			)}

			{showEditReason && (
				<div className="reason-for-edit-ability">
					{props.isCharacterOwner
						? 'You can modify this loot sheet because you own the character'
						: "You can modify this loot sheet because you're an admin of the guild"}
				</div>
			)}

			{!!showKeybindInfo && (
				<div className="loot-hotkeys">
					<HotkeyCombo>
						<Hotkey>{isMac() ? '⌘' : 'ctrl'}</Hotkey>
						<HotkeyComboJoiner />
						<Hotkey>shift</Hotkey>
						<HotkeyComboJoiner />
						<Hotkey>click</Hotkey>
					</HotkeyCombo>

					<span className="text">to quickly remove a loot selection</span>
				</div>
			)}
		</>
	);
};
