import React from 'react';
import {browserHistory as router} from 'react-router';

import type {Difficulties, Role, RaidGroupId} from '@constants/wow';

import type {SettingsDuck, LootDuck, DroptimizerReportDuck} from '@ducks';

import type {Character} from '../../models/character';
import type {Roster} from '../../models/roster';

import type {ISelectOption as IFilterOption} from '../Utils/FilterSelect';
import type {ISelectOption} from '../Utils/SelectInput';
import type * as LootContainer from './LootContainer';
import {TopSection} from './TopSection/TopSection';
import {ItemGroup} from './ItemGroup';
import {ExportLootInfoModal} from './ExportLootInfoModal';

export type InstanceFilterOption = WowInstanceId | RaidGroupId;

interface IUrl {
	wowInstanceId: InstanceFilterOption | undefined;
	difficulty: string | undefined;
	rosterId: number | undefined;
	queryId: string | undefined;
}

function route(data: IUrl) {
	const qs = new URLSearchParams();

	if (data.wowInstanceId) qs.append('i', data.wowInstanceId);
	if (data.difficulty) qs.append('d', data.difficulty);
	if (data.rosterId) qs.append('r', `${data.rosterId}`);
	if (data.queryId) qs.append('c', encodeURIComponent(data.queryId));

	router.push({
		pathname: window.location.pathname,
		search: `?${qs.toString()}`
	});
}

export interface IMapLootProps {
	canUseTagging: boolean;
	canUseExportLootInfo: boolean;

	isShowingRclcButton: boolean;
	isAllowedToEdit: boolean;
	isCharacterOwner: boolean;
	isCharacterAdmin: boolean;

	isDroptimizerRelativeGain: boolean;
	isGroupingBySlot: boolean;
	isShowingConduits: boolean;
	isBumping: boolean;

	characterDroptimizerItemByWowItemId: DroptimizerReportDuck.ItemByWowItemId | undefined;
	activeCharacter?: Character;
	characterId?: CharacterId;

	rosterId: RosterId | undefined;
	wowInstanceId: InstanceFilterOption;
	difficulty: Difficulties;
	queryId: string;

	ownCharacters: Character[];
	rosters: Roster[];

	filterOptions: IFilterOption[];
	instanceOptions: ISelectOption[];
	optionOptions: ISelectOption[];

	itemGroups: LootContainer.ItemGroup[];
	itemMap: LootContainer.ILootViewItemByWowItemId;
	rclcUrl: string;
}

type ILootProps = IMapLootProps & {
	onToggleDroptimizerRelativeGain: typeof SettingsDuck['setDroptimizerRelativeGain'];
	onToggleLootShowConduits: typeof SettingsDuck.setLootShowConduits;
	onToggleLootGroupBySlot: typeof SettingsDuck.setLootGroupBySlot;
	onLootNote: BoundThunk<typeof LootDuck['updateNote']>;
	onSelect: BoundThunk<typeof LootDuck.makeSelection>;
	onCoin: BoundThunk<typeof LootDuck.makeCoin>;
	onBump: BoundThunk<typeof LootDuck.bumpUpdated>;
};

interface IState {
	selectedRoles: Role[];
	/** Filter Wants to just the selected loot option */
	selectedLootOptionIds: LootOptionId[];

	isShowingExportLootModal: boolean;
}

export default class Loot extends React.Component<ILootProps, IState> {
	searchThrottle: number;

	constructor(props: ILootProps) {
		super(props);

		this.state = {
			selectedRoles: props.activeCharacter ? props.activeCharacter.roles : [],
			selectedLootOptionIds: [],

			isShowingExportLootModal: false
		};
	}

	componentDidUpdate(prevProps: ILootProps) {
		const id1 = prevProps.activeCharacter ? prevProps.activeCharacter.id : undefined;
		const id2 = this.props.activeCharacter ? this.props.activeCharacter.id : undefined;

		if (id1 !== id2 && this.props.activeCharacter) {
			// eslint-disable-next-line react/no-did-update-set-state
			this.setState({selectedRoles: this.props.activeCharacter.roles});
		}
	}

	/** Toggle the selected loot option to be used for filtering */
	handleLootOptionSelect = (lootOptionId: LootOptionId): void => {
		// set/unset the selected loot option
		this.setState((state) => {
			return {
				selectedLootOptionIds: state.selectedLootOptionIds.includes(lootOptionId)
					? // remove existing entry
					  state.selectedLootOptionIds.filter((x) => x !== lootOptionId)
					: // add new entry
					  [...state.selectedLootOptionIds, lootOptionId]
			};
		});
	};

	handleRoleSelect = (role: Role, isSelected: boolean) => {
		const newRoles = isSelected
			? [...this.state.selectedRoles, role]
			: this.state.selectedRoles.filter((x) => x !== role);

		this.setState({selectedRoles: newRoles});
	};

	handleCharacterSelect = (characterId: number | null) => {
		route({
			queryId: characterId ? String(characterId) : undefined,

			wowInstanceId: this.props.wowInstanceId,
			difficulty: this.props.difficulty,
			rosterId: this.props.rosterId
		});
	};

	handleRosterSelect = (rosterId: number) => {
		const roster = this.props.rosters.find((x) => x.id === rosterId);

		// if no roster just remove the roster filter
		if (!roster) {
			route({
				queryId: this.props.queryId,

				wowInstanceId: this.props.wowInstanceId,
				difficulty: this.props.difficulty,
				rosterId: undefined
			});

			return;
		}

		route({
			queryId: this.props.queryId,

			wowInstanceId: roster.wowInstanceId,
			difficulty: roster.difficulty,
			rosterId
		});
	};

	handleInstanceSelect = (wowInstanceId: InstanceFilterOption) => {
		route({
			difficulty: this.props.difficulty,
			queryId: this.props.queryId,
			rosterId: undefined,
			wowInstanceId
		});
	};

	handleDifficultySelect = (difficulty: string) => {
		route({
			wowInstanceId: this.props.wowInstanceId,
			queryId: this.props.queryId,
			rosterId: undefined,
			difficulty
		});
	};

	handleItemSelection = (charId: number, wowItemId: WowItemId, optionId?: number) => {
		return this.props.onSelect({
			difficulty: this.props.difficulty,
			characterId: charId,
			wowItemId,

			optionId
		});
	};

	handleNoteUpdate = (charId: number, wowItemId: string, note: string | null) => {
		return this.props.onLootNote({
			difficulty: this.props.difficulty,
			characterId: charId,
			wowItemId,
			note
		});
	};

	handleCoin = (wowBossId: WowBossId, isCoining: boolean) => {
		void this.props.onCoin({
			characterId: this.props.characterId!,
			difficulty: this.props.difficulty,
			wowBossId,

			isCoining
		});
	};

	handleBump = (characterId: number) => {
		void this.props.onBump(characterId);
	};

	handleShowExportLootInfoModal = () => {
		this.setState({isShowingExportLootModal: true});
	};

	render() {
		const itemGroups = this.props.itemGroups.map((itemGroup) => (
			<ItemGroup
				key={itemGroup.itemGroupId}
				onUpdateNote={this.handleNoteUpdate}
				onSelect={this.handleItemSelection}
				onSearch={this.handleCharacterSelect}
				// onCoin={this.handleCoin}
				isShowingDroptimizerRelativeGain={this.props.isDroptimizerRelativeGain}
				isAllowedToEdit={this.props.isAllowedToEdit}
				selectedRoles={this.state.selectedRoles}
				selectedLootOptionIds={this.state.selectedLootOptionIds}
				characterDroptimizerItemByWowItemId={
					this.props.characterDroptimizerItemByWowItemId
				}
				characterClass={
					this.props.activeCharacter ? this.props.activeCharacter.class : undefined
				}
				optionOptions={this.props.optionOptions}
				characterId={this.props.characterId}
				itemMap={this.props.itemMap}
				itemGroupId={itemGroup.itemGroupId}
				itemGroupName={itemGroup.itemGroupName}
				itemGroupImageUrl={itemGroup.itemGroupImageUrl}
				wowItemIds={itemGroup.wowItemIds}
			/>
		));

		const rosterOptions: ISelectOption[] = this.props.rosters.map((roster) => ({
			name: roster.name,
			id: roster.id
		}));
		rosterOptions.push({
			name: 'No roster',
			id: ''
		});

		return (
			<>
				{this.state.isShowingExportLootModal && (
					<ExportLootInfoModal
						onClose={() => this.setState({isShowingExportLootModal: false})}
						activeCharacter={this.props.activeCharacter}
						difficulty={this.props.difficulty}
						itemMap={this.props.itemMap}
					/>
				)}

				<div className="loot-view">
					<TopSection
						onShowExportLootInfoModal={this.handleShowExportLootInfoModal}
						onToggleDroptimizerRelativeGain={
							this.props.onToggleDroptimizerRelativeGain
						}
						onToggleLootShowConduits={this.props.onToggleLootShowConduits}
						onToggleLootGroupBySlot={this.props.onToggleLootGroupBySlot}
						onLootOptionSelect={this.handleLootOptionSelect}
						onRoleSelect={this.handleRoleSelect}
						onCharacterSelect={this.handleCharacterSelect}
						onDifficultySelect={this.handleDifficultySelect}
						onInstanceSelect={this.handleInstanceSelect}
						onRosterSelect={this.handleRosterSelect}
						onBump={this.handleBump}
						isShowingRclcButton={this.props.isShowingRclcButton}
						isAllowedToEdit={this.props.isAllowedToEdit}
						isCharacterAdmin={this.props.isCharacterAdmin}
						isCharacterOwner={this.props.isCharacterOwner}
						canUseExportLootInfo={this.props.canUseExportLootInfo}
						canUseTagging={this.props.canUseTagging}
						isDroptimizerRelativeGain={this.props.isDroptimizerRelativeGain}
						isGroupingBySlot={this.props.isGroupingBySlot}
						isShowingConduits={this.props.isShowingConduits}
						isBumping={this.props.isBumping}
						characterSelectOptions={this.props.filterOptions}
						rosterSelectOptions={rosterOptions}
						instanceSelectOptions={this.props.instanceOptions}
						lootOptionSelectOptions={this.props.optionOptions}
						activeCharacter={this.props.activeCharacter}
						ownCharacters={this.props.ownCharacters}
						activeRoles={this.state.selectedRoles}
						selectedLootOptionIds={this.state.selectedLootOptionIds}
						difficulty={this.props.difficulty}
						wowInstanceId={this.props.wowInstanceId}
						characterId={this.props.characterId}
						rosterId={this.props.rosterId}
						rclcUrl={this.props.rclcUrl}
					/>

					<div className="bosses">
						{this.props.queryId && !this.props.characterId ? (
							<div className="no-matches">
								<span>No matching character found</span>
							</div>
						) : (
							itemGroups
						)}
					</div>
				</div>
			</>
		);
	}
}
