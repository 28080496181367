export enum Role {
	MELEE = 'melee',
	RANGED = 'ranged',
	HEALER = 'healers',
	TANK = 'tanks'
}

/** General ordering of roles for various things e.g. roster role groups */
export const ORDERED_ROLES = [Role.MELEE, Role.RANGED, Role.HEALER, Role.TANK];

export enum Difficulties {
	NORMAL = 'normal',
	HEROIC = 'heroic',
	MYTHIC = 'mythic'
}

export const DifficultyDisplay: Record<Difficulties, string> = {
	normal: 'Normal',
	heroic: 'Heroic',
	mythic: 'Mythic'
};

export const DifficultyExport: Record<Difficulties, Uppercase<Difficulties>> = {
	normal: 'NORMAL',
	heroic: 'HEROIC',
	mythic: 'MYTHIC'
};

export const DEFAULT_DIFFICULTY = Difficulties.MYTHIC;

export enum RaidItemSlots {
	MISC = 'misc',
	RELIC = 'relic',
	CONDUIT = 'conduit',
	HEAD = 'head',
	NECK = 'neck',
	SHOULDER = 'shoulder',
	BACK = 'back',
	CHEST = 'chest',
	WRIST = 'wrist',
	HANDS = 'hands',
	WAIST = 'waist',
	LEGS = 'legs',
	FEET = 'feet',
	FINGER = 'finger',
	TRINKET = 'trinket',
	WEAPON = 'weapon',
	OFF_HAND = 'offhand',
	WHATEVER_TOKEN = 'whatever_token',
	WEAPON_TOKEN = 'weapon_token',
	LEGENDARY_RECIPE = 'legendary_recipe'
}

export const RAID_ITEM_SORT_ORDER_BY_SLOT: Record<RaidItemSlots, number> = {
	[RaidItemSlots.TRINKET]: 1,
	[RaidItemSlots.WEAPON_TOKEN]: 2,
	[RaidItemSlots.WEAPON]: 3,
	[RaidItemSlots.OFF_HAND]: 4,
	[RaidItemSlots.HEAD]: 5,
	[RaidItemSlots.NECK]: 6,
	[RaidItemSlots.SHOULDER]: 7,
	[RaidItemSlots.BACK]: 8,
	[RaidItemSlots.CHEST]: 9,
	[RaidItemSlots.WRIST]: 10,
	[RaidItemSlots.HANDS]: 11,
	[RaidItemSlots.WAIST]: 12,
	[RaidItemSlots.LEGS]: 13,
	[RaidItemSlots.FEET]: 14,
	[RaidItemSlots.FINGER]: 15,
	[RaidItemSlots.RELIC]: 16,
	[RaidItemSlots.WHATEVER_TOKEN]: 17,
	[RaidItemSlots.MISC]: 18,
	[RaidItemSlots.LEGENDARY_RECIPE]: 19,
	[RaidItemSlots.CONDUIT]: 20
};

export enum Classes {
	WARRIOR = 'warrior',
	PALADIN = 'paladin',
	HUNTER = 'hunter',
	ROGUE = 'rogue',
	PRIEST = 'priest',
	DEATH_KNIGHT = 'death_knight',
	SHAMAN = 'shaman',
	MAGE = 'mage',
	WARLOCK = 'warlock',
	MONK = 'monk',
	DRUID = 'druid',
	DEMON_HUNTER = 'demon_hunter',
	EVOKER = 'evoker'
}

export enum Spec {
	DK_BLOOD = 'dk_blood',
	DK_FROST = 'dk_frost',
	DK_UNHOLY = 'dk_unholy',

	DH_HAVOC = 'dh_havoc',
	DH_VENGEANCE = 'dh_vengeance',

	DRUID_RESTO = 'druid_resto',
	DRUID_GUARDIAN = 'druid_guardian',
	DRUID_FERAL = 'druid_feral',
	DRUID_BALANCE = 'druid_balance',

	HUNTER_BEAST_MASTERY = 'hunter_beast_mastery',
	HUNTER_MARKSMANSHIP = 'hunter_marksmanship',
	HUNTER_SURVIVAL = 'hunter_survival',

	MAGE_ARCANE = 'mage_arcane',
	MAGE_FIRE = 'mage_fire',
	MAGE_FROST = 'mage_frost',

	MONK_BREWMASTER = 'monk_brewmaster',
	MONK_MISTWEAVER = 'monk_mistweaver',
	MONK_WINDWALKER = 'monk_windwalker',

	PALADIN_HOLY = 'paladin_holy',
	PALADIN_PROT = 'paladin_prot',
	PALADIN_RET = 'paladin_ret',

	PRIEST_DISC = 'priest_disc',
	PRIEST_HOLY = 'priest_holy',
	PRIEST_SHADOW = 'priest_shadow',

	ROGUE_ASSASSINATION = 'rogue_assassination',
	ROGUE_OUTLAW = 'rogue_outlaw',
	ROGUE_SUBTLETY = 'rogue_subtlety',

	SHAMAN_ELE = 'shaman_ele',
	SHAMAN_ENHANCE = 'shaman_enhance',
	SHAMAN_RESTO = 'shaman_resto',

	WARLOCK_AFF = 'warlock_aff',
	WARLOCK_DEMO = 'warlock_demo',
	WARLOCK_DESTRO = 'warlock_destro',

	WARRIOR_ARMS = 'warrior_arms',
	WARRIOR_FURY = 'warrior_fury',
	WARRIOR_PROT = 'warrior_prot',

	EVOKER_DEVASTATION = 'evoker_devastation',
	EVOKER_PRESERVATION = 'evoker_preservation',
	EVOKER_AUGMENTATION = 'evoker_augmentation'
}

export const SPEC_TO_CLASS: Record<Spec, Classes> = {
	[Spec.DH_HAVOC]: Classes.DEMON_HUNTER,
	[Spec.DH_VENGEANCE]: Classes.DEMON_HUNTER,
	[Spec.DK_BLOOD]: Classes.DEATH_KNIGHT,
	[Spec.DK_FROST]: Classes.DEATH_KNIGHT,
	[Spec.DK_UNHOLY]: Classes.DEATH_KNIGHT,
	[Spec.DRUID_BALANCE]: Classes.DRUID,
	[Spec.DRUID_FERAL]: Classes.DRUID,
	[Spec.DRUID_GUARDIAN]: Classes.DRUID,
	[Spec.DRUID_RESTO]: Classes.DRUID,
	[Spec.HUNTER_BEAST_MASTERY]: Classes.HUNTER,
	[Spec.HUNTER_MARKSMANSHIP]: Classes.HUNTER,
	[Spec.HUNTER_SURVIVAL]: Classes.HUNTER,
	[Spec.MAGE_ARCANE]: Classes.MAGE,
	[Spec.MAGE_FIRE]: Classes.MAGE,
	[Spec.MAGE_FROST]: Classes.MAGE,
	[Spec.MONK_BREWMASTER]: Classes.MONK,
	[Spec.MONK_MISTWEAVER]: Classes.MONK,
	[Spec.MONK_WINDWALKER]: Classes.MONK,
	[Spec.PALADIN_HOLY]: Classes.PALADIN,
	[Spec.PALADIN_PROT]: Classes.PALADIN,
	[Spec.PALADIN_RET]: Classes.PALADIN,
	[Spec.PRIEST_DISC]: Classes.PRIEST,
	[Spec.PRIEST_HOLY]: Classes.PRIEST,
	[Spec.PRIEST_SHADOW]: Classes.PRIEST,
	[Spec.ROGUE_ASSASSINATION]: Classes.ROGUE,
	[Spec.ROGUE_OUTLAW]: Classes.ROGUE,
	[Spec.ROGUE_SUBTLETY]: Classes.ROGUE,
	[Spec.SHAMAN_ELE]: Classes.SHAMAN,
	[Spec.SHAMAN_ENHANCE]: Classes.SHAMAN,
	[Spec.SHAMAN_RESTO]: Classes.SHAMAN,
	[Spec.WARLOCK_AFF]: Classes.WARLOCK,
	[Spec.WARLOCK_DEMO]: Classes.WARLOCK,
	[Spec.WARLOCK_DESTRO]: Classes.WARLOCK,
	[Spec.WARRIOR_ARMS]: Classes.WARRIOR,
	[Spec.WARRIOR_FURY]: Classes.WARRIOR,
	[Spec.WARRIOR_PROT]: Classes.WARRIOR,
	[Spec.EVOKER_DEVASTATION]: Classes.EVOKER,
	[Spec.EVOKER_PRESERVATION]: Classes.EVOKER,
	[Spec.EVOKER_AUGMENTATION]: Classes.EVOKER
};

export const SPEC_TO_ROLE: Record<Spec, Role> = {
	[Spec.DH_HAVOC]: Role.MELEE,
	[Spec.DH_VENGEANCE]: Role.TANK,
	[Spec.DK_BLOOD]: Role.TANK,
	[Spec.DK_FROST]: Role.MELEE,
	[Spec.DK_UNHOLY]: Role.MELEE,
	[Spec.DRUID_BALANCE]: Role.RANGED,
	[Spec.DRUID_FERAL]: Role.MELEE,
	[Spec.DRUID_GUARDIAN]: Role.TANK,
	[Spec.DRUID_RESTO]: Role.HEALER,
	[Spec.HUNTER_BEAST_MASTERY]: Role.RANGED,
	[Spec.HUNTER_MARKSMANSHIP]: Role.RANGED,
	[Spec.HUNTER_SURVIVAL]: Role.MELEE,
	[Spec.MAGE_ARCANE]: Role.RANGED,
	[Spec.MAGE_FIRE]: Role.RANGED,
	[Spec.MAGE_FROST]: Role.RANGED,
	[Spec.MONK_BREWMASTER]: Role.TANK,
	[Spec.MONK_MISTWEAVER]: Role.HEALER,
	[Spec.MONK_WINDWALKER]: Role.MELEE,
	[Spec.PALADIN_HOLY]: Role.HEALER,
	[Spec.PALADIN_PROT]: Role.TANK,
	[Spec.PALADIN_RET]: Role.MELEE,
	[Spec.PRIEST_DISC]: Role.HEALER,
	[Spec.PRIEST_HOLY]: Role.HEALER,
	[Spec.PRIEST_SHADOW]: Role.RANGED,
	[Spec.ROGUE_ASSASSINATION]: Role.MELEE,
	[Spec.ROGUE_OUTLAW]: Role.MELEE,
	[Spec.ROGUE_SUBTLETY]: Role.MELEE,
	[Spec.SHAMAN_ELE]: Role.RANGED,
	[Spec.SHAMAN_ENHANCE]: Role.MELEE,
	[Spec.SHAMAN_RESTO]: Role.HEALER,
	[Spec.WARLOCK_AFF]: Role.RANGED,
	[Spec.WARLOCK_DEMO]: Role.RANGED,
	[Spec.WARLOCK_DESTRO]: Role.RANGED,
	[Spec.WARRIOR_ARMS]: Role.MELEE,
	[Spec.WARRIOR_FURY]: Role.MELEE,
	[Spec.WARRIOR_PROT]: Role.TANK,
	[Spec.EVOKER_DEVASTATION]: Role.RANGED,
	[Spec.EVOKER_PRESERVATION]: Role.HEALER,
	[Spec.EVOKER_AUGMENTATION]: Role.RANGED
};

// export const SPECS_BY_ROLE_BY_CLASS: Record<Classes, Record<Role, Spec[]>> = {
// 	[Classes.DEATH_KNIGHT]: {
// 		[Role.MELEE]: [Spec.DK_FROST, Spec.DK_UNHOLY],
// 		[Role.RANGED]: [],
// 		[Role.HEALER]: [],
// 		[Role.TANK]: [Spec.DK_BLOOD]
// 	},
// 	[Classes.DEMON_HUNTER]: {
// 		[Role.MELEE]: [Spec.DH_HAVOC],
// 		[Role.RANGED]: [],
// 		[Role.HEALER]: [],
// 		[Role.TANK]: [Spec.DH_VENGEANCE]
// 	},
// 	[Classes.DRUID]: {
// 		[Role.MELEE]: [Spec.DRUID_FERAL],
// 		[Role.RANGED]: [Spec.DRUID_BALANCE],
// 		[Role.HEALER]: [Spec.DRUID_RESTO],
// 		[Role.TANK]: [Spec.DRUID_GUARDIAN]
// 	},
// 	[Classes.HUNTER]: {
// 		[Role.MELEE]: [Spec.HUNTER_SURVIVAL],
// 		[Role.RANGED]: [],
// 		[Role.HEALER]: [Spec.HUNTER_BEAST_MASTERY, Spec.HUNTER_MARKSMANSHIP],
// 		[Role.TANK]: []
// 	},
// 	[Classes.MAGE]: {
// 		[Role.MELEE]: [],
// 		[Role.RANGED]: [Spec.MAGE_FIRE, Spec.MAGE_FROST, Spec.MAGE_ARCANE],
// 		[Role.HEALER]: [],
// 		[Role.TANK]: []
// 	},
// 	[Classes.MONK]: {
// 		[Role.MELEE]: [Spec.MONK_WINDWALKER],
// 		[Role.RANGED]: [],
// 		[Role.HEALER]: [Spec.MONK_MISTWEAVER],
// 		[Role.TANK]: [Spec.MONK_BREWMASTER]
// 	},
// 	[Classes.PALADIN]: {
// 		[Role.MELEE]: [Spec.PALADIN_RET],
// 		[Role.RANGED]: [],
// 		[Role.HEALER]: [Spec.PALADIN_HOLY],
// 		[Role.TANK]: [Spec.PALADIN_PROT]
// 	},
// 	[Classes.PRIEST]: {
// 		[Role.MELEE]: [],
// 		[Role.RANGED]: [Spec.PRIEST_SHADOW],
// 		[Role.HEALER]: [Spec.PRIEST_DISC, Spec.PRIEST_HOLY],
// 		[Role.TANK]: []
// 	},
// 	[Classes.ROGUE]: {
// 		[Role.MELEE]: [Spec.ROGUE_SUBTLETY, Spec.ROGUE_OUTLAW, Spec.ROGUE_ASSASSINATION],
// 		[Role.RANGED]: [],
// 		[Role.HEALER]: [],
// 		[Role.TANK]: []
// 	},
// 	[Classes.SHAMAN]: {
// 		[Role.MELEE]: [Spec.SHAMAN_ENHANCE],
// 		[Role.RANGED]: [Spec.SHAMAN_ELE],
// 		[Role.HEALER]: [Spec.SHAMAN_RESTO],
// 		[Role.TANK]: []
// 	},
// 	[Classes.WARLOCK]: {
// 		[Role.MELEE]: [],
// 		[Role.RANGED]: [Spec.WARLOCK_DEMO, Spec.WARLOCK_AFF, Spec.WARLOCK_DESTRO],
// 		[Role.HEALER]: [],
// 		[Role.TANK]: []
// 	},
// 	[Classes.WARRIOR]: {
// 		[Role.MELEE]: [Spec.WARRIOR_FURY, Spec.WARRIOR_ARMS],
// 		[Role.RANGED]: [],
// 		[Role.HEALER]: [],
// 		[Role.TANK]: [Spec.WARRIOR_PROT]
// 	}
// };

export enum Covenant {
	KYRIAN = 'kyrian',
	NECROLORDS = 'nercolords',
	NIGHT_FAE = 'night_fae',
	VENTHYR = 'venthyr'
}

export enum Regions {
	US = 'us',
	EU = 'eu'
}

export const UNKNOWN_CHARACTER_RANK = 100;

interface RaidGroup {
	id: string;
	label: string;
}

export const RAID_GROUPS: RaidGroup[] = [];
// export const RAID_GROUPS = [
// 	{
// 		id: 'awakened',
// 		label: 'Awakened'
// 	}
// ] as const;
export const RAID_GROUP_IDS = RAID_GROUPS.map((x) => x.id);
export type RaidGroupId = typeof RAID_GROUP_IDS[number];
