import React from 'react';

import {DifficultyExport, Difficulties} from '@constants/wow';

import type {Character} from '@models/character';

import {Modal} from '@components/Utils/Modal';

import type {ILootViewItemByWowItemId, ILootViewItem, IItemWant} from './LootContainer';

interface ExportedSelection {
	wowItemId: string;
	// wowCharacterId: string;
	characterName: string;
	characterRealm: string;
	difficulty: typeof DifficultyExport[keyof typeof DifficultyExport];
	selection: string;
	note: string | undefined;
	/** Absolute dps gain e.g. 2813.14 */
	absoluteGain: number | undefined;
	/** Percentage gain (between 0-1) e.g. 0.0123 */
	relativeGain: number | undefined;
}

interface ExportData {
	selections: ExportedSelection[];
}

function composeWants(
	isActiveCharacterOnly: boolean,
	lootViewItem: Pick<ILootViewItem, 'wantedByCharacter' | 'wantedBy'>
): IItemWant[] {
	if (isActiveCharacterOnly) {
		if (lootViewItem.wantedByCharacter) return [lootViewItem.wantedByCharacter];
		return [];
	}

	return lootViewItem.wantedBy;
}

function composeExportText(data: {
	itemMap: ILootViewItemByWowItemId;
	isActiveCharacterOnly: boolean;
	difficulty: Difficulties;
}): string {
	const exportData: ExportData = {selections: []};
	const displayDifficulty = DifficultyExport[data.difficulty];

	Object.values(data.itemMap).forEach((lootViewItem) => {
		const wants = composeWants(data.isActiveCharacterOnly, lootViewItem);

		wants.forEach((want) => {
			exportData.selections.push({
				wowItemId: lootViewItem.id,
				characterName: want.characterName,
				characterRealm: want.characterRealm,
				difficulty: displayDifficulty,
				selection: want.optionName,
				note: want.note || undefined,
				absoluteGain: want.droptimizerItemData?.absoluteDpsGain ?? undefined,
				relativeGain: want.droptimizerItemData?.relativeDpsGain ?? undefined
			});
		});
	});

	return JSON.stringify(exportData, null, 2);
}

interface OwnProps {
	onClose(): void;

	itemMap: ILootViewItemByWowItemId;
	activeCharacter: Character | undefined;
	difficulty: Difficulties;
}

type Props = OwnProps;

export const ExportLootInfoModal: React.FC<Props> = (props) => {
	const exportText = composeExportText({
		isActiveCharacterOnly: !!props.activeCharacter,
		difficulty: props.difficulty,
		itemMap: props.itemMap
	});

	return (
		<Modal onClose={props.onClose}>
			<div className="modal-content export-loot-info-modal">
				<div className="heading">Export Loot Information</div>

				{!!props.activeCharacter && (
					<p>Exporting loot selections for {props.activeCharacter.name}</p>
				)}

				<div className="inputs">
					<textarea
						className="input"
						value={exportText}
						autoFocus={true}
						readOnly={true}
					/>
				</div>
			</div>
		</Modal>
	);
};
